import { getKcContext } from 'keycloakify/login';
import { KcContext as KcContextBase } from 'keycloakify/login/kcContext';
import getTestMode from 'utils/getTestMode';
import { Mocks } from './Mocks';

const testMode = getTestMode();

// From OAuthGrantBean.java
export type ClientScopeEntry = {
  consentScreenText: string;
  guiOrder: string;
  dynamicScopeParameter?: string;
};

export type UserCredential = {
  id: string;
  userLabel: string
};

export type AuthOptions = {
  id: string;
  label: string;
};

export type Policies = Partial<{
  lowerCase: string // num of lowercase chars
  length: string // length of a password
  upperCase: string // num of upper case letter
  specialChars: string // num of special chars
  digits: string // num of digits
  forceExpiredPasswordChange: string // days when pass exp, eg 365
  maxLength: string // max length of a password
  notEmail: string
  notUsername: string
  passwordHistory: string
  regexPattern: string
}>;

export const { kcContext } = getKcContext<{
  pageId: 'login.ftl';
  url: KcContextBase.Login['url'];
  auth: {
    authenticationSelections: readonly {
      authExecId: string;
      displayName: string;
      helpText: string;
    }[];
  };
} | {
  pageId: 'qr-login.ftl';
  qrCode: string;
  pollUri: string;
  url: KcContextBase.Login['url'];
  auth: {
    authenticationSelections: readonly {
      authExecId: string;
      displayName: string;
      helpText: string;
    }[];
  };
} | {
  pageId: 'register-user-profile.ftl';
  profile: KcContextBase.RegisterUserProfile['profile'] & { policies: Policies };
} | {
  pageId: 'update-email.ftl';
  login: KcContextBase.UpdateEmail['url'] & { policies: Policies };
} | {
  pageId: 'login-update-password.ftl';
  login: KcContextBase.LoginUpdatePassword['url'] & { policies: Policies };
} | {
  pageId: 'info.ftl';
  requiredActions: KcContextBase.Info['requiredActions'];
} | {
  pageId: 'smscode.ftl';
  url: KcContextBase.Login['url'];
} | {
  pageId: 'login-page-expired.ftl';
  url: KcContextBase.LoginPageExpired['url'];
} | {
  pageId: 'login-oauth-grant.ftl';
  oauth: {
    clientScopesRequested: readonly ClientScopeEntry[];
    code: string;
  };
  url: KcContextBase.Login['url'] & {
    oauthAction: string;
  };
} | {
  pageId: 'login-config-totp.ftl',
} | {
  pageId: 'login-otp.ftl',
} | {
  pageId: 'update-phone-number-form.ftl',
} | {
  pageId: 'verify-phone-number-form.ftl',
} | {
  pageId: 'pick-sms-credential.ftl',
  smsLogin: {
    selectedCredentialId: string;
    url: KcContextBase.Login['url']
    userSmsCredentials: UserCredential[]
  }
} | {
  pageId: 'pick-2fa-auth.ftl',
  authOptions: {
    availableOptions: AuthOptions[]
  }
} | {
  pageId: 'update-email-form.ftl'
} | {
  pageId: 'verify-email-form.ftl'
} | {
  pageId: 'pick-email-credential.ftl'
  login: {
    selectedCredentialId: string;
    url: KcContextBase.Login['url'];
    credentials: UserCredential[];
  }
} | {
  pageId: 'emailcode.ftl'
} | {
  pageId: 'register-email-code.ftl'
} | {
  pageId: 'delete-account-confirm.ftl'
}
>(
  // there are issues with mocks vs actual required types with Typescript 5.4.5 - but these mocks all works just fine so we ignore the errors
  // @ts-expect-error
  testMode === 'login'
    ? {
      mockPageId: 'register-user-profile.ftl',
      mockData: Mocks,
    }
    : {},
);

export type KcContext = NonNullable<typeof kcContext>;
