import { StrictMode, lazy, Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { kcContext as kcAccountThemeContext } from './account/kcContext';
import { kcContext as kcLoginThemeContext } from './login/kcContext';
import './index.scss';
import '@humane/common/accessibility-rings.css';

const KcLoginThemeApp = lazy(() => import('./login/App'));
const KcAccountThemeApp = lazy(() => import('./account/KcApp'));

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <Suspense>
      {(() => {
        // for now kcLoginThemeContext will be always defined
        if (kcLoginThemeContext !== undefined) {
          return <KcLoginThemeApp kcContext={kcLoginThemeContext} />;
        }

        // TO-DO create a kcAccountThemeContext mock
        if (kcAccountThemeContext !== undefined) {
          return <KcAccountThemeApp kcContext={kcAccountThemeContext} />;
        }

        throw new Error('No KCContext');
      })()}
    </Suspense>
  </StrictMode>,
);
